import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useRouter } from "next/router";

import { TechnicalGlitch } from "src/components/ColoredIcons/TechnicalGlitch";
import { ErrorLayout } from "src/components/Layout/Page/Error";
import { getStaticProps } from "src/utilities/getStaticProps";
import { useTillTranslations } from "src/hooks/i18n/useTillTranslations";

export default function InternalError(): JSX.Element {
  const { t } = useTillTranslations("ERROR_PAGES");

  const router = useRouter();
  const handleRedirect = () => {
    router.push("/");
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={2}
    >
      <Grid item justifyContent="center" alignItems="center">
        <TechnicalGlitch />
      </Grid>

      <Grid item>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={1}
        >
          <Grid item>
            <Typography component="h2" variant="h1">
              {t("internalError.header")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align="center">
              {t("internalError.description")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleRedirect}
        >
          {t("cta.returnHome")}
        </Button>
      </Grid>
    </Grid>
  );
}

InternalError.getLayout = function Layout(page: JSX.Element): JSX.Element {
  return (
    <ErrorLayout title="ERROR_PAGES.internalError.header">{page}</ErrorLayout>
  );
};

export { getStaticProps };
