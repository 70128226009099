import { Box, SxProps, Theme } from "@mui/material";
import React, { ReactNode } from "react";
import Image from "next/image";

import { ECountryIcons } from "types/enums/ECountryIcons";
import { ECardIcons } from "types/enums/ECardIcons";

export enum EIconSize {
  XS = "0.875rem",
  SM = "1rem",
  MD = "1.125rem",
  LG = "1.5rem",
  XL = "3rem",
}

export enum EIcon {
  ADD = "/SVG/add_icon.svg",
  ADD_CIRCLED = "/SVG/add_circled_icon.svg",
  ADD_CIRCLED_ICON_BLACK = "/SVG/add_circled_icon_black.svg",
  AIRPLANE_A_MEDIUM = "/SVG/airplane_a_medium_icon.svg",
  AIRPLANE_B_MEDIUM = "/SVG/airplane_b_medium_icon.svg",
  AMOUNT = "/SVG/amount_icon.svg",
  AMOUNT_GRAY = "/SVG/amount_icon_gray.svg",
  API_MEDIUM = "/SVG/api_medium_icon.svg",
  APPLICATION_BEEN_SUBMITTED = "/SVG/applicationbeensubmitted_large_icon.svg",
  AUS_FLAG = "/SVG/aus_flag_icon.svg",
  ARROW_UP_GREEN = "/SVG/arrow_up_green.svg",
  ARROW_DOWN_RED = "/SVG/arrow_down_red.svg",
  ARROW_RIGHT_ORANGE = "/SVG/arrow_right_orange.svg",
  AUTO_A_MEDIUM = "/SVG/auto_a_medium_icon.svg",
  AUTO_B_MEDIUM = "/SVG/auto_b_medium_icon.svg",
  AUTO_C_MEDIUM = "/SVG/auto_c_medium_icon.svg",
  BANK = "/SVG/bank_icon.svg",
  BANK_GRAY = "/SVG/bank_icon_gray.svg",
  BANK_BLACK = "/SVG/bank_icon_black.svg",
  BANK_ORANGE = "/SVG/bank_orange_icon.svg",
  BATCH_MEDIUM = "/SVG/batch_medium_icon.svg",
  BENEFICIALOWNER = "/SVG/beneficialowner_icon.svg",
  BNPL_MEDIUM = "/SVG/bnpl_medium_icon.svg",
  BPAY_MEDIUM = "/SVG/bpay_medium_icon.svg",
  BRAIN_MEDIUM = "/SVG/brain_medium_icon.svg",
  BRIEFCASE_REPEAT_MEDIUM = "/SVG/briefcase_repeat_medium_icon.svg",
  BUSINESSINSIGHTS = "/SVG/businessinsights_icon.svg",
  CALENDAR = "/SVG/calendar_icon.svg",
  CANCEL_STATUS_ICON = "/SVG/cross_circled_gray_icon.svg",
  CALENDAR_GREY = "/SVG/calendar_icon_grey.svg",
  CASHFUNCTION_MEDIUM = "/SVG/cashfunction_medium_icon.svg",
  CERTIFICATE = "/SVG/certificate_icon.svg",
  CERTIFICATE_GRAY = "/SVG/certificate_icon_gray.svg",
  CHATBUBBLE_MEDIUM = "/SVG/chatbubble_medium_icon.svg",
  CHECKBOX = "/SVG/checkbox_icon.svg",
  CHECKBOX_NOT_VALIDATE = "/SVG/checkbox_icon_not_validate.svg",
  CHECKBOX_EMPTY_DISABLED = "/SVG/checkbox_empty_disable_icon.svg",
  CHECKBOX_FILLED_DISABLED = "/SVG/checkbox_filled_disable_icon.svg",
  CHECKBOX_FILLED = "/SVG/checkbox_filled_icon.svg",
  CHECKBOX_FILLED_INDECISIVE = "/SVG/checkbox_filled_dash_icon.svg",
  CHEVRON_DOWN = "/SVG/chevron_down.svg",
  CHEVRON_DOWN_GREY = "/SVG/chevron_down_grey.svg",
  CHEVRON_DOWN_WHITE = "/SVG/chevron_down_white.svg",
  CHEVRON_LEFT = "/SVG/chevron_left.svg",
  CHEVRON_LEFT_GREY = "/SVG/chevron_left_grey.svg",
  CHEVRON_LEFT_WHITE = "/SVG/chevron_left_white.svg",
  CHEVRON_RIGHT = "/SVG/chevron_right.svg",
  CHEVRON_RIGHT_GREY = "/SVG/chevron_right_grey.svg",
  CHEVRON_RIGHT_WHITE = "/SVG/chevron_right_white.svg",
  CHEVRON_UP = "/SVG/chevron_up.svg",
  CHEVRON_UP_GREY = "/SVG/chevron_up_grey.svg",
  CIRCLED_ALERT = "/SVG/circled_alert_icon.svg",
  CLOCKWITHTICK_MEDIUM = "/SVG/clockwithtick_medium_icon.svg",
  CLOSE = "/SVG/close_icon.svg",
  COG = "/SVG/cog.svg",
  COGCIRCUIT_MEDIUM = "/SVG/cogcircuit_medium_icon.svg",
  COINWITHARROW_MEDIUM = "/SVG/coinwitharrow_medium_icon.svg",
  COLLAPSELEFT = "/SVG/collapseleft_icon.svg",
  COMPUTERCOG_MEDIUM = "/SVG/computercog_medium_icon.svg",
  CONNECTEDWORLD_MEDIUM = "/SVG/connectedworld_medium_icon.svg",
  COPY = "/SVG/copy_icon.svg",
  CREDITCARD = "/SVG/creditcard_icon.svg",
  CREDITCARD_GRAY = "/SVG/creditcard_icon_gray.svg",
  CREDITCARD_EDIT = "/SVG/credit_card_edit_icon.svg",
  CROSS = "/SVG/cross_icon.svg",
  CROSS_RED = "/SVG/cross_red_icon.svg",
  CUSTOMERS = "/SVG/customers_icon.svg",
  DASHBOARD = "/SVG/dashboard_icon.svg",
  CUSTOMIZE = "/SVG/customize_icon.svg",
  CUSTOMIZE_ORANGE = "/SVG/customize_orange_icon.svg",
  DELETE = "/SVG/delete_icon.svg",
  DELETE_RED = "/SVG/delete_icon_red.svg",
  DELETE_CIRCLED_ICON_RED = "/SVG/delete_circled_icon_red.svg",
  DESKTOP_NOTSELECTED = "/SVG/desktop_notselected_icon.svg",
  DESKTOP_SELECTED = "/SVG/desktop_selected_icon.svg",
  DEVICE_HUB = "/SVG/device_hub_icon.svg",
  DOB = "/SVG/dob_icon.svg",
  DOB_GRAY = "/SVG/dob_icon_gray.svg",
  DOCUMENT_GREY = "/SVG/document_icon_grey.svg",
  DOWNLOAD = "/SVG/download_icon.svg",
  DOWNLOAD_ORANGE = "/SVG/download_orange_icon.svg",
  EDIT = "/SVG/edit_icon.svg",
  EDIT_CIRCLED = "/SVG/edit_circled_icon.svg",
  EDIT_CIRCLED_RED = "/SVG/edit_circled_icon_red.svg",
  EDIT_INCIRCLE = "/SVG/edit_incircle_icon.svg",
  EMAIL = "/SVG/email_icon.svg",
  ERROR_STATUS_ICON = "/SVG/error_status_icon.svg",
  EXPAND = "/SVG/expand_icon.svg",
  EXPANDLEFT = "/SVG/expandleft_icon.svg",
  FILTER = "/SVG/filter_icon.svg",
  FILTER_ORANGE = "/SVG/filter_orange_icon.svg",
  FORGOTPASS_LARGE = "/SVG/forgotpass_large_icon.svg",
  GENERIC_PHONE = "/SVG/generic_phone_icon.svg",
  GROCERY_MEDIUM = "/SVG/grocery_medium_icon.svg",
  HANDSHAKE_MEDIUM = "/SVG/handshake_medium_icon.svg",
  HELPDESK = "/SVG/helpdesk_icon.svg",
  HIDE_PASSWORD = "/SVG/hidepassword_icon.svg",
  HIDEPASS = "/SVG/hidepass_icon.svg",
  HOSPITALITY_A_MEDIUM = "/SVG/hospitality_a_medium_icon.svg",
  HOSPITALITY_B_MEDIUM = "/SVG/hospitality_b_medium_icon.svg",
  HPP_MEDIUM = "/SVG/hpp_medium_icon.svg",
  INFO = "/SVG/info_icon.svg",
  INFOBOOK = "/SVG/infobook_icon.svg",
  ITEMS = "/SVG/items_icon.svg",
  IN_PROGRESS_STATUS_ICON = "/SVG/in_progress_status_icon.svg",
  KNOWLEDGE_B_MEDIUM = "/SVG/knowledge_b_medium_icon.svg",
  LAPTOP_ORANGE = "/SVG/laptop_icon_orange.svg",
  LAST_LOGIN = "/SVG/lastlogin_icon.svg",
  LASTLOGIN = "/SVG/lastlogin_icon.svg",
  LINK = "/SVG/link_icon.svg",
  LINK_LEGEND = "/SVG/link_legend_icon.svg",
  LINK_LEGEND_GRAY = "/SVG/link_legend_icon_gray.svg",
  LINKEDACCOUNTS = "/SVG/linkedaccounts_icon.svg",
  LINKSENTTIME_LARGE = "/SVG/linksenttime_large_icon.svg",
  LINKSENTTIME_LARGE_WITHOPACITY = "/SVG/linksenttime_large_withOpacity_icon.svg",
  LOCATION_LEGEND = "/SVG/location_legend_icon.svg",
  LOCATION_LEGEND_GRAY = "/SVG/location_legend_icon_gray.svg",
  LOCATIONS = "/SVG/locations_icon.svg",
  LOCATIONS_GREY = "/SVG/locations_icon_grey.svg",
  LOADING_ORANGE_SMALL = "/SVG/loading_icon_orange_small.svg",
  LOADING_ORANGE_LARGE = "/SVG/loading_icon_orange_large.svg",
  LOGIN_1_MEDIUM = "/SVG/login_1_medium_icon.svg",
  LOGIN_2_MEDIUM = "/SVG/login_2_medium_icon.svg",
  LOGIN_3_MEDIUM = "/SVG/login_3_medium_icon.svg",
  LOGIN_4_MEDIUM = "/SVG/login_4_medium_icon.svg",
  MAINOFFICE = "/SVG/mainoffice_icon.svg",
  MAINOFFICE_GRAY = "/SVG/mainoffice_icon_gray.svg",
  MAINOFFICE_LEGEND = "/SVG/mainoffice_legend_icon.svg",
  MAINGROUPOFFICE = "/SVG/maingroupoffice_icon.svg",
  MAINGROUPOFFICE_GREY = "/SVG/maingroupoffice_icon_grey.svg",
  MANAGERUSER = "/SVG/manageruser_icon.svg",
  MERCHANT_ACCOUNT = "/SVG/merchantacc_icon.svg",
  MERCHANT_ACCOUNT_GREY = "/SVG/merchantacc_icon_grey.svg",
  MERCHANT_LOGO_DEFAULT = "/SVG/merchant_medium_icon.svg",
  MESSAGE_GREY = "/SVG/message_grey_icon.svg",
  MID_GREY = "/SVG/mid_icon_grey.svg",
  MINUS = "/SVG/minus_icon.svg",
  MINUS_GREY = "/SVG/minus_icon_grey.svg",
  MOBILE_NOTSELECTED = "/SVG/mobile_notselected_icon.svg",
  MOBILE_NOTSELECTED_GRAY = "/SVG/mobile_notselected_icon_gray.svg",
  MOBILE_SELECTED = "/SVG/mobile_selected_icon.svg",
  MORE_MEDIUM = "/SVG/more_medium_icon.svg",
  MORE_VERTICAL = "/SVG/morevertical_icon.svg",
  MORE_VERTICAL_GREY = "/SVG/morevertical_icon_grey.svg",
  MOTO_MEDIUM = "/SVG/moto_medium_icon.svg",
  NOTIFICATION = "/SVG/notification_icon.svg",
  ONBOARDING_SUCCESS_LARGE = "/SVG/onboardingsuccess_large_icon.svg",
  ONLINE = "/SVG/online_icon.svg",
  ONLINE_GREY = "/SVG/online_icon_gray.svg",
  ONLINE_LIGHT = "/SVG/online_icon_light.svg",
  ONLINE_PRIMARY = "/SVG/online_icon_primary.svg",
  OPERATIONS = "/SVG/operations_icon.svg",
  OPERATIONS_GREY = "/SVG/operations_icon_grey.svg",
  PAPERPLANE_A = "/SVG/paperplane_a_icon.svg",
  PAPERPLANE_A_MEDIUM = "/SVG/paperplane_a_medium_icon.svg",
  PAPERPLANE_B = "/SVG/paperplane_b_icon.svg",
  PAPERPLANE_B_MEDIUM = "/SVG/paperplane_b_medium_icon.svg",
  PASSCODE_ORANGE = "/SVG/passcode_orange_icon.svg",
  PASSPORT_ORANGE = "/SVG/passport_orange_icon.svg",
  PASSWORD = "/SVG/password_icon.svg",
  PASSWORD_LOCK = "/SVG/password_lock_icon.svg",
  PASSWORD_DOTTED = "/SVG/password_dotted_icon.svg",
  PBL_LOGIN = "/SVG/pbl_login_icon.svg",
  PBL_MEDIUM = "/SVG/pbl_medium_icon.svg",
  PEOPLE_A_COG = "/SVG/people_a_cog_icon.svg",
  PEOPLE_A_MEDIUM = "/SVG/people_a_medium_icon.svg",
  PEOPLE_B_MEDIUM = "/SVG/people_b_medium_icon.svg",
  PEOPLE_COG_B = "/SVG/people_cog_b_icon.svg",
  PERCENTAGE = "/SVG/percentage_icon.svg",
  PHONE_CIRCLED = "/SVG/phone_circled_icon.svg",
  PHONE_ORANGE = "/SVG/phone_orange_icon.svg",
  PLUS = "/SVG/plus_icon.svg",
  PLUS_GREY = "/SVG/plus_icon_grey.svg",
  POS = "/SVG/pos_icon.svg",
  POS_GREY = "/SVG/pos_icon_grey.svg",
  POSTERMINAL_MEDIUM = "/SVG/posterminal_medium_icon.svg",
  PROCESSOR_GREY = "/SVG/processor_icon_grey.svg",
  QUESTION_MARK_RED = "/SVG/question_mark_red.svg",
  REVISED_STATUS_ICON = "/SVG/revised_status_icon.svg",
  RADIOBUTTON = "/SVG/radiobutton_icon.svg",
  RADIOBUTTON_FILLED = "/SVG/radiobutton_fill_icon.svg",
  RADIOBUTTON_EMPTY_DISABLED = "/SVG/radiobutton_empty_disable_icon.svg",
  RADIOBUTTON_FILLED_DISABLED = "/SVG/radiobutton_fill_disable_icon.svg",
  RECEIPT_ROLL_LARGE = "/SVG/receipt_roll_large.svg",
  RESPONSIVE_MENU_ICON = "/SVG/responsive_menu_icon.svg",
  RECURRING_MEDIUM = "/SVG/recurring_medium_icon.svg",
  RETRY = "/SVG/retry_icon.svg",
  RETRY_ORANGE = "/SVG/retry_orange_icon.svg",
  RIGHTCHANGEARROW = "/SVG/rightchangearrow_icon.svg",
  RISKMANAGEMENT = "/SVG/riskmanagement_icon.svg",
  ROCKET_MEDIUM = "/SVG/rocket_medium_icon.svg",
  ROWS = "/SVG/rows_icon.svg",
  SEARCH = "/SVG/search_icon.svg",
  SENT_RECEIPT_ICON = "/SVG/sent_receipt_icon.svg",
  SETTING = "/SVG/setting_icon.svg",
  SETTLEMENTS = "/SVG/settlements_icon.svg",
  SETTLEMENTS_GREY = "/SVG/settlements_icon_grey.svg",
  SHOPPING_BAG = "/SVG/shoppingbag_icon.svg",
  SHOPPING_CART = "/SVG/shoppingcart_icon.svg",
  SHOW_PASSWORD = "/SVG/showpassword_icon.svg",
  SHOWPASS = "/SVG/showpass_icon.svg",
  SMALL_CROSS = "/SVG/small_cross_icon.svg",
  SMILEY_A = "/SVG/smiley_a_icon.svg",
  SMILEY_A_MEDIUM = "/SVG/smiley_a_medium_icon.svg",
  SMILEY_B = "/SVG/smiley_b_icon.svg",
  SMILEY_B_MEDIUM = "/SVG/smiley_b_medium_icon.svg",
  STAIRSWITHARROW_MEDIUM = "/SVG/stairswitharrow_medium_icon.svg",
  STORE = "/SVG/store_icon.svg",
  STORE_GREY = "/SVG/store_icon_gray.svg",
  STORE_LIGHT = "/SVG/store_icon_light.svg",
  STORE_PRIMARY = "/SVG/store_icon_primary.svg",
  SUCCESS_STATUS_ICON = "/SVG/success_status_icon.svg",
  SWAP = "/SVG/swap_icon.svg",
  SWITCH = "/SVG/switch_icon.svg",
  TABLE = "/SVG/table_icon.svg",
  TABLE_GRAY = "/SVG/table_gray_icon.svg",
  TAP_AND_PLAY = "/SVG/tap_and_play_icon.svg",
  TERMINAL_ICON = "/SVG/terminal_icon.svg",
  TICK_GREEN = "/SVG/tick_green_icon.svg",
  TICK_ORANGE = "/SVG/tick_orange_icon.svg",
  TICK_SUCCESS = "/SVG/tick_success_icon.svg",
  TICK_RED = "/SVG/tick_red_icon.svg",
  TICK_SUCCESS_IN_CIRCLE = "/SVG/successsubmit_medium_icon.svg",
  TICK_IN_CIRCLE_ORANGE = "/SVG/tick_circled_orange_icon.svg",
  TICK_IN_CIRCLE_GREEN = "/SVG/tick_circled_green_icon.svg",
  TICK_FILLED_CIRCLE_GREEN = "/SVG/tick_filled_circle_green.svg",
  TICK_FILLED_CIRCLE_RED = "/SVG/tick_filled_circle_red.svg",
  TILL_LOGO = "/SVG/till_logo.svg",
  TILL_MEDIUM = "/SVG/till_medium_icon.svg",
  TILL_SHIELD = "/SVG/till_shield_icon.svg",
  TILL_VISION_LOGIN = "/SVG/tillvision_login_icon.svg",
  TIME = "/SVG/time_icon.svg",
  TOOLTIP_GREY = "/SVG/tooltip_grey_icon.svg",
  TOOLTIP = "/SVG/tooltip_notselected_icon.svg",
  TOOLTIP_SELECTED = "/SVG/tooltip_selected_icon.svg",
  TRANSACTION = "/SVG/transaction_icon.svg",
  TRANSACTION_GRAY = "/SVG/transaction_gray_icon.svg",
  TRANSACTION_GREEN_COMPLETE = "/SVG/transaction_complete_icon.svg",
  TRANSACTION_RED_DECLINED = "/SVG/transaction_declined_icon.svg",
  TRANSACTION_SQUARE = "/SVG/transaction_square_icon.svg",
  TREE = "/SVG/tree_icon.svg",
  TWO_FA = "/SVG/2fa_icon.svg",
  UPLOAD = "/SVG/upload_icon.svg",
  USER = "/SVG/user_profile.svg",
  USER_GREY = "/SVG/user_profile_grey.svg",
  USER_OUTLINE = "/SVG/user_outline_icon.svg",
  TECHNICAL_ERROR = "/SVG/technical_error.svg",
  WALLET_MEDIUM = "/SVG/wallet_medium_icon.svg",
  WARNING = "/SVG/warning_icon.svg",
  WARNING_STATUS = "/SVG/warning_status_icon.svg",
  WARNING_YELLOW = "/SVG/warning_yellow_icon.svg",
  WEBSITE = "/SVG/website_icon.svg",
  WEBSITE_GREY = "/SVG/website_grey_icon.svg",
  WEBSITE_BUSINESS = "/SVG/website_business_icon.svg",
  VIEW_DETAILS = "/SVG/sidebar_view_more_icon.svg",
  TILL_TERMINAL = "/SVG/till_terminal_icon.svg",
  CIRCLE_CHECKBOX_CHECKED = "/SVG/circle_checkbox_checked.svg",
  CIRCLE_CHECKBOX_UNCHECKED = "/SVG/circle_checkbox_unchecked.svg",
  DRIVER_LICENSE = "/SVG/au-driver-license.svg",
  PASSPORT = "/SVG/au-passport.svg",
  ARROW_UP = "/SVG/arrow-up.svg",
  ARROW_DOWN = "/SVG/arrow-down.svg",
  ROUND_CIRCLE_RED = "/SVG/expand-button.svg",
  ROUND_CIRCLE_TRANSPARENT = "/SVG/expand-button_transparent.svg",
  X_CIRCLE_RED = "/SVG/x_circle_red_icon.svg",
  PARTY_POPPER = "/SVG/party_popper.svg",
  EXCLAMATION_CIRCLE = "/SVG/exclamation_circle.svg",
}

export interface IIconProps {
  size?: EIconSize;
  icon: EIcon | ECountryIcons | ECardIcons;
  alt?: string;
  sx?: SxProps<Theme>;
  iconComponent?: ReactNode;
}

export function Icon({
  size = EIconSize.MD,
  icon,
  alt = "",
  sx,
  iconComponent,
}: IIconProps): JSX.Element {
  return (
    <Box height={size} width={size} sx={sx} position="relative">
      {iconComponent}
      {!iconComponent && (
        <Image
          fill
          src={icon || (icon as string)}
          alt={alt}
          data-testid={`${icon}-test-icon`}
        />
      )}
    </Box>
  );
}
